import * as React from "react"

import Seo from "../components/Seo"

const privacyPage = () => (
  <>
    <Seo title="Terminos y Condiciones" />
    <div className="terms">
      <h1>Aviso de privacidad integral</h1>
      <p>
        En cumplimiento a la Ley Federal de Protección de Datos Personales en
        Posesión de Particulares y su Reglamento, Centro Hidalguense de Estudios
        Superiores, Sociedad Civil, en adelante “CENHIES”, con domicilio en
        boulevard Luis Donaldo Colosio No. 101, colonia Ampliación Santa Julia,
        Pachuca, Hidalgo, C.P. 42086, con número telefónico (771) 28 13 890; al
        ser el responsable de la obtención, uso y protección de sus datos
        personales, a través del presente aviso de privacidad le informa lo
        siguiente:
      </p>
      <blockquote>
        El presente aviso de privacidad tiene por objeto la protección de los
        datos personales de los integrantes de la comunidad CENHIES (aspirantes,
        alumnos, exalumnos, egresados y empleados), así como de los prestadores
        de servicios con quienes CENHIES sostiene cualquier tipo de relación y
        de los visitantes a la institución, mediante su tratamiento legítimo,
        controlado e informado, a efecto de garantizar su privacidad, así como
        su derecho a la autodeterminación informativa.
      </blockquote>
      <h2>I.- Finalidades primarias y secundarias</h2>
      <p>
        Sus datos personales recabados por CENHIES, de acuerdo a la categoría en
        que se encuentre tendrán las siguientes finalidades:
      </p>
      <h3>Aspirantes</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>
          Contacto, mismo que puede ser personal y/o electrónico/digital y/o
          telefónico.
        </li>
        <li>
          Llenado de formularios para solicitar informes, mismos que pueden ser
          físicos y/o electrónicos, ya sean proporcionados directamente por
          CENHIES, o terceros que brinden este servicio.
        </li>
        <li>
          Brindar información sobre la prestación de servicios educativos que
          ofrece CENHIES (oferta académica).
        </li>
        <li>
          Procesos de admisión, incluyendo la aplicación de exámenes de
          conocimientos generales y específicos, entrevistas, así como exámenes
          psicométricos.
        </li>
        <li>
          Procesos de solicitud de becas o financiamiento, así como estudios
          socioeconómicos.
        </li>
        <li>Seguimiento a solicitudes de admisión.</li>
        <li>Validación de información y/o documentación.</li>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Contactos posteriores con la finalidad de conocer la calidad de los
          servicios prestados, tales como encuestas y retroalimentaciones.
        </li>
        <li>
          Publicitar nuestros servicios, oferta académica y eventos, ya sea por
          medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          de CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
        <li>
          Enviar información personalizada, así como notificar avisos y
          resoluciones de los órganos internos de CENHIES.
        </li>
      </ul>
      <h3>Alumnos</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>Validación de información y documentación.</li>
        <li>
          Procesos de inscripción y reinscripción, alta en el sistema de gestión
          escolar, expedientes académicos y credencialización de alumnos.
        </li>
        <li>Credencialización de alumnos.</li>
        <li>
          Procesos de pagos (en área de caja CENHIES, sucursal bancaria y/o a
          través de servicios en línea prestados por terceros) para alumnos y/o
          personas encargadas de cubrir los costos por los servicios que presta
          CENHIES; así como procesos de cobranza dirigidos a estos mismos.
        </li>
        <li>
          Proporcionar los servicios educativos en economía creativa que ofrece
          CENHIES, acorde con el programa académico al que el alumno esté
          inscrito.
        </li>
        <li>
          Procesos de gestión académica relacionados con los alumnos, incluyendo
          sin limitar, aplicación de cualquier tipo de evaluaciones, registro de
          calificaciones, historial académico, intercambio estudiantil,
          prácticas profesionales, servicio social, resoluciones de becas,
          gestiones con seguros, bajas temporales y/o definitivas, y cualquier
          otro relacionado con la gestión académica.
        </li>
        <li>
          Procesos de órganos colegiados académicos (sanciones académicas y/o
          disciplinarias, revisiones de calificaciones, entre otros).
        </li>
        <li>
          Procesos de gestión administrativa y/o de servicios escolares
          relacionados con los alumnos, trámites internos y externos ante
          autoridades educativas, incluyendo sin limitar, expedición de títulos
          de grado, cédulas, certificados, equivalencias y revalidaciones de
          estudios, así como cualquier otro relacionado con la gestión
          administrativa.
        </li>
        <li>Solicitud de beca, así como estudios socioeconómicos.</li>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
        <li>
          Registro en actividades extracurriculares (sociales, culturales,
          deportivas, académicas, entre otras).
        </li>
        <li>
          Préstamo de materiales propiedad de CENHIES, para actividades
          académicas y extracurriculares.
        </li>
        <li>Gestión académica con herramientas tecnológicas.</li>
        <li>Directorio de alumnos.</li>
        <li>Evaluación psicológica y seguimiento.</li>
        <li>
          Contar con historial médico con fines de prevención, para que en caso
          de emergencia médica se pueda brindar la atención inmediata requerida,
          y seguir los protocolos de CENHIES para tales casos, tomando en cuenta
          la información previamente proporcionada en relación con algún
          padecimiento, alergia o alguna otra cuestión que deba ser tomada en
          consideración; asimismo informar a las personas indicadas como
          referencia o contacto para emergencias.
        </li>
        <li>Comunicaciones internas.</li>
        <li>Atención a dudas y sugerencias.</li>
        <li>
          Cumplir con los marcos normativos aplicables, así como con cualquier
          requerimiento de las autoridades y/o dependencias gubernamentales en
          México y/o en el extranjero.
        </li>
        <li>Solicitar su colaboración para acreditaciones de CENHIES.</li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Contactos posteriores con la finalidad de conocer la calidad de los
          servicios prestados, tales como encuestas y retroalimentaciones.
        </li>
        <li>
          Publicitar nuestros servicios, oferta académica y eventos, ya sea por
          medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          de CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
        <li>
          Enviar información personalizada, así como notificar avisos y
          resoluciones de los órganos internos de CENHIES.
        </li>
        <li>Divulgar datos académicos disociados para efectos estadísticos.</li>
      </ul>
      <h3>Exalumnos y egresados</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>
          Registro y actualización de base de datos de exalumnos y/o egresados.
        </li>
        <li>Credencialización de exalumnos y/o egresados.</li>
        <li>
          Brindar y difundir información actualizada sobre CENHIES, tales como
          oferta académica, invitaciones, entre otra.
        </li>
        <li>
          Dar a conocer la atención, servicios y beneficios con que cuentan como
          exalumnos y/o egresados.
        </li>
        <li>
          Procesos de pagos (en área de caja CENHIES, sucursal bancaria y/o a
          través de servicios en línea prestados por terceros), por los
          servicios que presta CENHIES.
        </li>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Contactos posteriores con la finalidad de conocer la calidad de los
          servicios prestados e información relacionada con su trayectoria
          profesional.
        </li>
        <li>
          Publicitar nuestros servicios, oferta académica y eventos, ya sea por
          medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          de CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
        <li>
          Enviar información personalizada, así como notificar avisos y
          resoluciones de órganos internos de CENHIES.
        </li>
        <li>
          Divulgar datos académicos disasociados para efectos estadísticos.
        </li>
        <li>
          Validar información de estudios de exalumnos o egresados con terceros
          que lo soliciten.
        </li>
        <li>
          Promoción de logros y actividades en general de exalumnos y/o
          egresados, para dar a conocer su trayectoria académica y/o
          profesional.
        </li>
        <li>Ofrecer y promocionar vacantes de empleo.</li>
      </ul>
      <h3>Candidatos – Empleados</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>Ofrecer y promocionar vacantes de empleo.</li>
        <li>Procesos de reclutamiento y selección.</li>
        <li>Evaluaciones psicométricas.</li>
        <li>Estudios socioeconómicos.</li>
        <li>
          Procesos de contratación de personal, alta en el sistema de gestión de
          personal, expedientes laborales y credencialización de empleados,
          asignación de equipo y materiales de trabajo.
        </li>
        <li>
          Procesos de recursos humanos tales como: pago de nómina, evaluaciones
          de desempeño, actividades de integración, planes de desarrollo
          profesional para empleados, capacitaciones, becas, beneficios y
          descuentos para empleados en los servicios que ofrece CENHIES.
        </li>
        <li>
          Procesos de sanciones disciplinarias y bajas de personal, mismas que
          pueden ser video grabadas.
        </li>
        <li>
          Participación en proyectos incluyendo sin limitar, intercambios,
          congresos, simposios, festivales, ferias, talleres, entre otros.
        </li>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
        <li>Directorio y organigrama institucional.</li>
        <li>Evaluación psicológica y seguimiento.</li>
        <li>
          Contar con historial médico con fines de prevención, para que en caso
          de emergencia médica se pueda brindar la atención inmediata requerida,
          y seguir los protocolos de CENHIES para tales casos, tomando en cuenta
          la información previamente proporcionada en relación con algún
          padecimiento, alergia o alguna otra cuestión que deba ser tomada en
          consideración; asimismo informar a las personas indicadas como
          referencia o contacto para emergencias.
        </li>
        <li>
          Comunicaciones internas, envío de información personalizada, así como
          la notificación de avisos y resoluciones de órganos internos de
          CENHIES.
        </li>
        <li>Atención a dudas y sugerencias.</li>
        <li>
          Cumplir con los marcos normativos aplicables, así como con cualquier
          requerimiento de las autoridades y/o dependencias gubernamentales en
          México y/o en el extranjero.
        </li>
        <li>
          Solicitar la colaboración del titular para acreditaciones de CENHIES.
        </li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Al término de la relación laboral con CENHIES, proporcionar
          referencias laborales a terceros que el titular autorice.
        </li>
        <li>
          Publicitar los servicios, oferta académica y eventos de CENHIES, ya
          sea por medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
      </ul>
      <h3>Prestadores de servicio</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>
          Validar la documentación e información que proporciona a CENHIES.
        </li>
        <li>
          Procesos de alta como proveedor, y de contratación como prestador de
          servicios en caso de requerir sus servicios.
        </li>
        <li>Seguimiento a los servicios contratados.</li>
        <li>Pago de servicios proporcionados.</li>
        <li>
          Cumplir con los marcos normativos aplicables, así como con cualquier
          requerimiento de las autoridades y/o dependencias gubernamentales en
          México y/o en el extranjero.
        </li>
        <li>Solicitar su colaboración para acreditaciones de CENHIES.</li>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
        <li>Notificar avisos.</li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Publicitar los servicios, oferta académica y eventos de CENHIES, ya
          sea por medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          de CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
      </ul>
      <h3>Visitantes</h3>
      <h4>1. Finalidades primarias:</h4>
      <ul>
        <li>
          Registros vehiculares (control de accesos, estancias y salidas de las
          instalaciones).
        </li>
        <li>
          Registros personales (control de accesos, asistencias y salidas de las
          instalaciones).
        </li>
        <li>
          Registro en procesos de seguridad y vigilancia, incluyendo a través
          del CCTV (Circuito Cerrado de Televisión y Video vigilancia).
        </li>
        <li>
          Validar la documentación e información que comparte con CENHIES.
        </li>
      </ul>
      <h4>2. Finalidades secundarias:</h4>
      <ul>
        <li>
          Publicitar los servicios, oferta académica y eventos de CENHIES, ya
          sea por medios impresos, electrónicos, telefónicos y/o presenciales.
        </li>
        <li>
          Difundir y mostrar con fines promocionales y/o publicitarios, dentro
          y/o fuera del territorio nacional, por cualquier medio presente o
          futuro permitido por la ley, su asistencia y participación en los
          eventos que tengan verificativo dentro y/o fuera de las instalaciones
          de CENHIES, misma que podrá ser registrada a través de fotografías,
          audiograbaciones y/o videograbaciones.
        </li>
      </ul>
      <p>
        Si no desea que CENHIES trate sus datos personales para finalidades
        secundarias, deberá hacerlo saber por escrito al Departamento de
        Protección de Datos Personales de CENHIES.
      </p>
      <h2>II.- Tipos de datos personales que se recaban</h2>
      <p>
        Los datos personales que recaba CENHIES serán acorde a la categoría en
        que se encuentre el titular y las finalidades aplicables, siendo estos
        de manera enunciativa más no limitativa: a) datos de contacto, b) datos
        de identificación, c) datos académicos, datos de conocimientos y datos
        generales, d) datos de referencias laborales y/o familiares, y de
        experiencia laboral/profesional y datos de estado de salud presente y/o
        futuro (ambos considerados como datos sensibles), así como f) datos
        financieros y/o patrimoniales para el caso de pagos, becas. CENHIES
        recabará sus datos personales de manera presencial, telefónica, a través
        de formularios en sus sitios web y/o en sus redes sociales, así como por
        cualquier otro medio presente o futuro posible y permitido por la ley.
      </p>
      <h2>III.- Consentimiento expreso</h2>
      <p>
        Para el caso de que CENHIES requiera tratar datos personales sensibles,
        financieros y/o patrimoniales, solicitará de manera previa a su
        tratamiento y protección, su consentimiento expreso y por escrito del
        presente aviso de privacidad.
      </p>
      <h2>IV.- Transferencias de datos personales</h2>
      <p>
        Para la debida operación y cumplimiento de los fines que persigue
        CENHIES, los datos personales que proporcione, podrán ser transferidos a
        terceros, ya sean nacionales y/o extranjeros, sin su previa autorización
        en los siguientes casos:
      </p>
      <ul>
        <li>
          Cuando esté previsto en una ley, y se tenga que transferir a
          autoridades federales, estatales o locales para el debido cumplimiento
          normativo.
        </li>
        <li>
          Cuando sea necesaria para la prevención, el diagnóstico, atención y
          tratamiento médico.
        </li>
        <li>
          Cuando sea por virtud de un contrato celebrado o por celebrar en
          interés suyo, por CENHIES y un tercero;
        </li>
        <li>
          Cuando sea necesaria o legalmente exigida para la salvaguarda de un
          interés público, o para la procuración o administración de justicia;
        </li>
        <li>
          Cuando sea precisa para el reconocimiento, ejercicio o defensa de un
          derecho en un proceso judicial, y
        </li>
        <li>
          Cuando sea para el mantenimiento o cumplimiento de una relación
          jurídica entre CENHIES y Usted.
        </li>
      </ul>
      <p>
        Para los siguientes casos, se requerirá de su previo consentimiento para
        transferir sus datos personales; la transferencia no incluye datos
        sensibles, financieros y/o patrimoniales:
      </p>
      <ul>
        <li>
          Cuando sea necesario validar información y documentación de carácter
          académico y/o laboral que haya proporcionado, con entes públicos y/o
          privados responsables de su expedición.
        </li>
        <li>
          Cuando sea necesario entregar referencias académicas y/o laborales a
          entes públicos y/o privados que le sean solicitadas a CENHIES, para
          validar información y/o documentación de que sea titular.
        </li>
        <li>
          Cuando instituciones públicas y/o privadas deseen contactarlo para
          invitarlo a eventos o actividades académicas y/o culturales.
        </li>
      </ul>
      <p>
        En caso de que manifieste su oposición para que sus datos personales
        sean transferidos (ver apartado VI.), se entenderá que el consentimiento
        ha sido denegado y, por tanto, su uso se limitará para la prestación de
        los servicios que brinda CENHIES, no pudiendo acceder a cualquier otro
        servicio de tercero alguno, en caso contrario, si NO manifiesta su
        oposición se entenderá que SÍ ha otorgado el consentimiento para
        transferir sus datos personales a terceros.
      </p>
      <h2>V.- Medidas de seguridad</h2>
      <p>
        CENHIES cuenta con un Departamento de Privacidad de Datos Personales
        encargado de la vigilancia y aplicación de la política de privacidad de
        datos personales, asimismo derivado de dicha política se han
        implementado medidas de seguridad físicas, técnicas y administrativas,
        necesarias para el adecuado tratamiento y almacenamiento de sus datos
        personales, y en su caso, prevenir en la medida de lo posible el uso y/o
        la divulgación no autorizada de los mismos, ya sea a través de
        capacitaciones periódicas al personal, acceso limitado de información
        únicamente a personal autorizado que requiera del conocimiento de la
        misma para el desempeño de funciones específicas, implementación de
        nuevos procesos, adquisición y mantenimiento de hardware y/o software
        que cumplan con estándares de seguridad en información, espacios de
        trabajo libres de información y/o documentación, promoción de
        cumplimiento de nuestra política de privacidad interna y externamente
        con terceros a través de contratos específicos o cláusulas contractuales
        referentes a la privacidad de datos y la confidencialidad de la
        información.
      </p>
      <h2>VI.- Contacto, derechos ARCO y revocación del consentimiento</h2>
      <p>
        Para todo lo relacionado con el tratamiento y protección de sus datos
        personales, el oportuno ejercicio de sus derechos de Acceso,
        Rectificación, Cancelación y Oposición (ARCO), así como para solicitar
        la revocación del consentimiento o limitación de uso de sus datos
        personales, deberá realizar su solicitud por escrito, dirigiéndola al
        Departamento de Protección de Datos Personales de CENHIES; dicha
        solicitud deberá ser enviada a la dirección de correo electrónico{" "}
        <a href="mailto:derechosarco@cenhies.edu.mx">
          derechosarco@cenhies.edu.mx
        </a>
        , o bien, entregada de manera física en nuestras instalaciones,
        debidamente acusada de recibido por parte de personal del Departamento
        antes mencionado.
      </p>
      <p>Tiene derecho a ejercer sus derechos ARCO cuando:</p>
      <ul>
        <li>
          <b>Acceso.-</b> Requiera conocer qué tipo de datos tiene CENHIES de su
          propiedad, y para qué los utiliza.
        </li>
        <li>
          <b>Rectificación.-</b> Requiera corregir sus datos por ser inexactos,
          incompletos y/o deban actualizarse.
        </li>
        <li>
          <b>Cancelación.-</b> Requiera que CENHIES elimine de sus bases de
          datos, su información personal.
        </li>
        <li>
          <b>Oposición.-</b> Requiera oponerse al uso de sus datos para fines
          específicos.
        </li>
      </ul>
      <p>En su solicitud deberá acompañar los siguientes requisitos:</p>
      <ol>
        <li>
          Su nombre completo, domicilio y otro medio para comunicarle la
          respuesta a su solicitud.
        </li>
        <li>
          Los documentos que acrediten la identidad, o en su caso, la
          representación legal del titular por el que se esté actuando.
        </li>
        <li>
          La descripción clara y precisa de los datos personales respecto de los
          que se busca ejercer alguno de los derechos antes mencionados.
        </li>
        <li>
          Cualquier otro elemento o documento que facilite la localización de
          los datos personales.
        </li>
        <li>
          Para el caso de solicitudes de rectificación, deberá indicar las
          modificaciones que deban realizarse, y aportar la documentación que
          sustente su petición.
        </li>
      </ol>
      <p>
        CENHIES le comunicará en un plazo máximo de 20 (veinte) días hábiles
        contados a partir de la fecha en que reciba la solicitud, la
        determinación adoptada a efecto de que, si resulta procedente, se haga
        efectiva la misma dentro de los 15 (quince) días siguientes a la fecha
        en que se comunique la respuesta.
      </p>
      <p>
        CENHIES podrá negar el acceso a los datos personales, la rectificación,
        cancelación o concesión de la oposición al tratamiento de los mismos, en
        los siguientes supuestos:
      </p>
      <ol>
        <li>
          Cuando el solicitante no sea el titular de los datos personales, o el
          representante legal no esté debidamente acreditado para ello;
        </li>
        <li>
          Cuando en su base de datos no se encuentren los datos personales del
          solicitante;
        </li>
        <li>Cuando se lesionen los derechos de un tercero;</li>
        <li>
          Cuando exista un impedimento legal, o la resolución de una autoridad
          competente que restrinja el acceso a los datos personales, o que no
          permita la rectificación, cancelación u oposición de los mismos;
        </li>
        <li>
          Cuando la rectificación, cancelación u oposición haya sido previamente
          realizada.
        </li>
      </ol>
      <p>
        En este mismo orden de ideas, se le informa que tiene el derecho de
        revocar en cualquier momento el consentimiento otorgado a CENHIES para
        el tratamiento de sus datos, o limitar el uso de los mismos; en caso de
        que la petición no resulte procedente en términos de la normatividad
        aplicable, le será informada en los mismos términos señalados
        anteriormente.
      </p>
      <h2>VII.- Modificación y/o actualización al aviso de privacidad</h2>
      <p>
        CENHIES podrá modificar y/o actualizar su aviso de privacidad a su sola
        discreción. De modificarse y/o actualizarse el aviso de privacidad,
        CENHIES pondrá públicamente en su sitio web:{" "}
        <a href="https://www.cenhies.edu.mx">www.cenhies.edu.mx</a>, la
        modificación y/o actualización del aviso de privacidad, por lo cual lo
        invitamos a que visite esta sección periódicamente con la finalidad de
        que permanezca informado de cualquier cambio. Todo cambio sustancial, a
        menos que éste se derive de una reforma al marco jurídico mexicano, o a
        una orden de autoridad competente, se notificará a través del sitio web
        institucional con 30 días naturales de anticipación a que surta efectos
        la modificación correspondiente.
      </p>
    </div>
  </>
)

export default privacyPage
